import React from 'react'
import { Table } from 'antd'
const TransactionsTable = ({
    data,
    loading,
    columns,
    rowKey = 'transaction_id'
}) => (
    <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        size="middle"
        loading={loading}
        rowKey={rowKey}
    />
)

export default TransactionsTable
