import React, { useCallback } from 'react'
import { Container } from './index.styled'
import ReferralEditor from '../ReferralEditor'
import ReferralPreviewCard from '../ReferralPreviewCard'

export default function ReferralItem({
  index,
  editabled,
  data,
  onChange,
  onSave,
  onCancel,
  updateWrapperImage
}) {
  const { editing } = data

  const handleChange = useCallback(
    (newData) => {
      onChange(index, { ...data, ...newData })
    },
    [data, onChange]
  )

  const handleUpdateImage = useCallback(
    (image) => {
      handleChange(image)
      updateWrapperImage(index, image)
    },
    [handleChange, updateWrapperImage]
  )

  return (
    <Container>
      {editing ? (
        <ReferralEditor
          index={index}
          data={data}
          onChange={handleChange}
          onSave={onSave}
          onCancel={onCancel}
          onUpdateImage={handleUpdateImage}
        />
      ) : (
        <ReferralPreviewCard
          className="card"
          editabled={editabled}
          {...data}
          onEdit={handleChange}
        />
      )}
    </Container>
  )
}
