import axios, { formDataRequest } from './axios'

export const createAR = (personId) =>
  axios.post(`/assessment_review?person_id=${personId}`)

export const getList = (personId) =>
  axios.get(`/assessment_review?person_id=${personId}`)

export const getARDetail = (id) => axios.get(`/assessment_review/detail/${id}`)

export const update = (params) => axios.putJson('/assessment_review', params)

export const publish = (id) => axios.post(`/assessment_review/${id}/publish`)

export const deleteAR = (id) => axios.delete(`/assessment_review/${id}`)

export const createReferral = (id, params) =>
  axios.post(`/assessment_review/${id}/referral`, params)

export const updateReferral = (id, params) =>
  axios.putJson(`/assessment_review/referral/${id}`, params)

export const uploadReferralImage = (file, id) => {
  const path = `assessment_review/referral/image/${id}`
  const data = new FormData()
  data.append('file', file)
  return formDataRequest({
    method: 'put',
    path,
    data
  })
}

export const getDynamicData = (personId, index) =>
  axios.get(`/assessment_review/dynamic-data/${index}?person_id=${personId}`)
// axios.get(`/assessment_review/dynamic-data?person_id=${personId}`)

/**
 *  1： healthSpanDomainSummary，lifeStyleSummary
    2.: cardio, trends
    3: bone and body comp
    4: cognitive
    5: balance
    6: movement
    7: muscle
    8: aerobic
 * @param {*} person_id
 * @param {*} index 
 * @returns 
 */
export const getDynamicDataV2 = (personId, index, reportId) =>
  axios.get(
    `/assessment_review/dynamic-part/${index}?person_id=${personId}&report_id=${reportId}`
  )

export const createNote = (arId, params) =>
  axios.postJson(`/assessment_review/${arId}/note`, params)

export const updateNote = (id, params) =>
  axios.putJson(`/assessment_review/note/${id}`, params)

export const deleteNote = (id) => axios.delete(`/assessment_review/note/${id}`)

export const notifyClient = (id, message) =>
  axios.postJson(`/assessment_review/${id}/notify`, { message })

export const changeLog = (id) =>
  axios.get(`/assessment_review/change_log?id=${id}`)

export const editCardiometabolicTrends = (id, params) =>
  axios.putJson(`/assessment_review/${id}/trends-metric-keys`, params)

export const editAssessmentReivewMetricValue = (id, params) =>
  axios.putJson(`/assessment_review/${id}/metric-value`, params)
