import React from 'react'

const SessionSummary = ({ sessionsPurchased, sessionsUsed }) => {
  const sessionsRemaining =
    sessionsPurchased !== null
      ? sessionsPurchased - sessionsUsed
      : 0

  return (
    <div className="transaction-container">
      <div className="total-sessions-container">
        <p className="session-header">Total Sessions Purchased (Current Membership)</p>
        <p className="sessions-value">{(sessionsPurchased || 0).toFixed(2)}</p>
      </div>
      <div className="total-sessions-container">
        <p className="session-header">Sessions Used (Current Membership)</p>
        <p className="sessions-value">{(sessionsUsed || 0).toFixed(2)}</p>
      </div>
      <div className="sessions-remaining-container">
        <p className="session-header">Sessions Remaining (Current Membership)</p>
        <p className="sessions-value">{(sessionsRemaining || 0).toFixed(2)}</p>
      </div>
    </div>
  )
}

export default SessionSummary
