import React, { Component } from 'react'
import moment from 'moment'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Card, Divider, Input } from 'antd'
import PropTypes from 'prop-types'
import { pattern } from '../../utils/constant'
import { CardContainer } from './styles/card'
import ClientActionEditIcon from '../../asserts/icon/client/icon-client-edit.svg'
import {HSDScoreConfig} from '../assessmentReview/comp/HSDContainer'
const { Meta } = Card

class HealthCard extends Component {
  static propTypes = {
    compare: PropTypes.object,
    save: PropTypes.func,
    globalEdit: PropTypes.bool,
    isDefaultValue: PropTypes.bool
  }

  state = {
    isEditing: false,
    score: null
  }

  save = async (setToDefaultValue) => {
    const { type, save } = this.props
    await save(type, setToDefaultValue)
    this.setState({
      isEditing: false
    })
  }

  cancel = () => {
    const { type, cancel } = this.props
    cancel(type)
    this.setState({
      isEditing: false
    })
  }

  getChange(score, compareScore) {
    if (!score || !compareScore) return null

    const change = score - compareScore
    return (
      <>
        {change > 0 ? (
          <CaretUpOutlined style={{ color: '#40C47C' }} />
        ) : (
          <CaretDownOutlined style={{ color: '#FF6B00' }} />
        )}{' '}
        {Math.abs(change)}
      </>
    )
  }

  getStatus = (score, name) => {
    const { compare } = this.props
    let compareScore
    if (compare) {
      compareScore =
        (compare.score_manual && compare.score_manual.value) ||
        compare.score_auto
    }
    let status = ''

    if (!score && score !== 0) {
      status = 'NONE'
    } else {
      const config = HSDScoreConfig(score)
      status = config.text
    }

    return { status, compareScore }
  }

  checkSocreLegal = (score) => {
    return score ? score >= 5 && score <= 99 : true
  }

  editHandle = (fromProps) => {
    const { score_manual, score_auto, compare } = this.props
    this.setState({
      latest: (score_manual && score_manual.value) || score_auto,
      previous:
        (compare && compare.score_manual && compare.score_manual.value) ||
        (compare && compare.score_auto),
      isEditing: fromProps ? false : true
    })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.globalEdit && this.props.globalEdit) {
      this.editHandle(true)
    }
  }

  renderFooter(score, status, compareScore) {
    const { latest_test_date, compare } = this.props
    const currentLatestData = latest_test_date
    const compareLatestData = compare && compare.latest_test_date

    return (
      <footer>
        {`${
          currentLatestData
            ? moment(currentLatestData).format('MMM DD, YYYY')
            : '--'
        } vs. ${
          compareLatestData
            ? moment(compareLatestData).format('MMM DD, YYYY')
            : '--'
        }`}
      </footer>
    )
  }

  renderInputGroup() {
    const { onChangeHandle, compare, type, latest_test_date } = this.props
    const { latest, previous } = this.state
    const latestLegal = this.checkSocreLegal(latest)
    const previousLegal = this.checkSocreLegal(previous)

    return (
      <div className="score">
        <div>
          {latest_test_date ? (
            <Input
              className={latestLegal ? '' : 'tip-input'}
              width={40}
              placeholder="--"
              value={latest}
              onChange={(e) => {
                const score = e.target.value
                if ((pattern.integer.test(score) && score <= 100) || !score) {
                  onChangeHandle(score, type, 'latest')
                  this.setState({ latest: score })
                }
              }}
            />
          ) : (
            '--'
          )}
          <span className="split">/</span>{' '}
          {compare.latest_test_date ? (
            <Input
              className={previousLegal ? '' : 'tip-input'}
              width={40}
              placeholder="--"
              value={previous}
              onChange={(e) => {
                const score = e.target.value
                if ((pattern.integer.test(score) && score <= 100) || !score) {
                  onChangeHandle(score, type, 'previous')
                  this.setState({ previous: score })
                }
              }}
            />
          ) : (
            <span className="compare-data">--</span>
          )}
        </div>
        {!latestLegal || !previousLegal ? (
          <span className="tip-text">Input number between 5 to 99</span>
        ) : (
          <></>
        )}
      </div>
    )
  }

  render() {
    const {
      loading,
      name,
      description,
      compare,
      missing_metrics,
      jumpToDataset,
      routeTypes,
      loginRole,
      score_manual,
      score_auto,
      globalEdit,
      isDefaultValue,
      latest_test_date
    } = this.props
    const { isEditing, latest, previous } = this.state
    const latestLegal = this.checkSocreLegal(latest)
    const previousLegal = this.checkSocreLegal(previous)
    const score = (score_manual && score_manual.value) || score_auto
    const { status, compareScore } = this.getStatus(score, name)
    const isEditingStatus = globalEdit || isEditing
    const disEdit =
      this.props.disEdit ||
      (!latest_test_date && (!compare || !compare.latest_test_date))
    return (
      <CardContainer status={status}>
        <Card
          loading={loading}
          className={`health-card ${loginRole} ${
            !globalEdit && isEditing ? 'editing' : ''
          } ${disEdit || globalEdit ? 'disEdit' : ''}`}
        >
          <Meta
            description={
              loading ? (
                <></>
              ) : (
                <>
                  <div className={`meta-content ${isEditing ? 'editing' : ''}`}>
                    <header>
                      <div className="name">
                        {name}
                        <div className="status">
                          {status !== 'NONE' ? status : 'No Data'}
                        </div>
                      </div>
                      {isEditingStatus &&
                      (latest_test_date || compare.latest_test_date) ? (
                        this.renderInputGroup()
                      ) : (
                        <div className="score">
                          {status !== 'NONE' ? score : '--'}
                          {compare && (
                            <>
                              {' '}
                              <span className="split">/</span>{' '}
                              <span className="compare-data">
                                {compareScore ? compareScore : '--'}
                              </span>
                              <span className="change">
                                {this.getChange(score, compareScore)}
                              </span>
                            </>
                          )}
                        </div>
                      )}
                    </header>
                    <Divider />
                    {status !== 'NONE' ||
                    loginRole === 'User' ||
                    loginRole === 'Service_Provider' ? (
                      <div className="description">{description}</div>
                    ) : (
                      <div className="description">
                        <div>
                          The following metrics are missing or incorrect. Please
                          check and try again.
                        </div>
                        {missing_metrics && (
                          <ul>
                            {missing_metrics.map((item, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  jumpToDataset && jumpToDataset(item.group)
                                }
                                style={{
                                  color:'#e75f25',
                                  textDecoration:'underline',
                                  cursor:
                                    routeTypes && routeTypes.match(item.type)
                                      ? 'pointer'
                                      : 'auto'
                                }}
                              >
                                {item.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}

                    {this.renderFooter(score, status, compareScore)}
                    {!isEditingStatus && !disEdit && (
                      <div className="hover">
                        <img
                          src={ClientActionEditIcon}
                          alt="edit"
                          onClick={() => this.editHandle()} //{() => this.setState({ isEditing: true })}
                        />
                      </div>
                    )}
                  </div>
                  {isEditing && !globalEdit && (
                    <div className="actions">
                      <span
                        className={`btn ${
                          isDefaultValue && compare.isDefaultValue
                            ? 'disabled'
                            : ''
                        }`}
                        onClick={() => {
                          if (!isDefaultValue || !compare.isDefaultValue) {
                            this.save(true)
                          }
                        }}
                      >
                        Set to default value
                      </span>
                      <span>
                        <span className="btn" onClick={this.cancel}>
                          CANCEL
                        </span>
                        <span
                          className={`${'btn'} ${
                            !latestLegal || !previousLegal ? 'disabled' : ''
                          }`}
                          onClick={() =>
                            !latestLegal || !previousLegal ? {} : this.save()
                          }
                        >
                          Save
                        </span>
                      </span>
                    </div>
                  )}
                </>
              )
            }
          />
        </Card>
      </CardContainer>
    )
  }
}

export default HealthCard
