import React, { useState } from 'react'
import _, { get } from 'lodash'
import styled from 'styled-components'
import { Button } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ViewIcon, SimpleUploadIcon } from '../icons' // BellIcon
import Wrapper from './wrapper'
import Banner from './comp/banner'
import Cardiometabolic from './sections/cardiometabolic'
import Aerobic from './sections/aerobic'
import MovementQuality from './sections/movementQuality'
import CognitiveHealth from './sections/cognitiveHealth'
import PageLoading from '../UI/status/loading'
import IntroductionSection from './sections/IntroductionSection'
import HealthCompassSection from './sections/HealthCompassSection'
import PersonalInfoSection from './sections/PersonalInfoSection'
import BoneHealth from './sections/BoneHealth'
import BodyCompResultSection from './sections/BodyCompResultSection'
import Balance from './sections/balance'
import MuscleSection from './sections/MuscleSection'
import RecommendationSection from './sections/RecommendationSection'

export default Wrapper(DraftReport)

export const DraftContainer = styled.div`
  font-family: Gilroy;
  padding-bottom: 68px;
  section {
    padding: 48px 200px;
    background: #ffffff;
    margin-bottom: 20px;
    .section-title {
      color: #4c6072;
      font-size: 32px;
      letter-spacing: 0.3px;
      text-transform: capitalize;
      line-height: 150%;
      padding: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #e3e6ea;
    }
    .sub-title {
      margin-top: 32px;
      margin-bottom: 16px;
      font-size: 18px;
      color: #4c6072;
      letter-spacing: 0.3px;
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
    }
  }
`

const ErrorContainer = styled.div`
  display: flex;
  margin-top: 112px;
  font-size: 18px;
  font-family: Gilroy;
  color: #323f4a;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .message {
    margin-bottom: 8px;
  }
`

export const Footer = styled.footer`
  width: 1440px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 17px 200px;
  position: fixed;
  bottom: 0;
  background: #fcfdfd;
  box-shadow: 0px -10px 10px rgba(51, 41, 41, 0.05);
  div > button {
    margin-left: 10px;
  }
  div > button:first-child {
    margin-left: 0;
  }
`

function DraftReport(props) {
  const {
    list,
    person,
    loginRole,
    arDetail,
    latest_bmi,
    latest_height,
    latest_weight,
    device_info,
    residence,
    dynamicData,
    handlePublish,
    handlePreview,
    handleSave,
    showActivity,
    // handleNotify,
    handleCommendSave,
    HandleSaveScore,
    updateEditingCount,
    editingCount,
    createdFailed,
    ageScopes,
    infinityLoadCount,
    maxLoadCount,
    next,
    hideSectionHandle,
    hideSection,
    trendMetrics,
    onUpdateReferral,
    updateArDetail
  } = props

  const previous = get(dynamicData, 'healthSpanDomainSummary.1', {})
  const latest = get(dynamicData, 'healthSpanDomainSummary.0', {})

  const getDataList = (snapshot, graph_type) => {
    return list.map((item) => {
      // const catchScore = catchEditScore.find(
      //   (score) => score.type === item.type && score.graph_type === graph_type
      // )
      const detail =
        (snapshot[item.type] && _.cloneDeep(snapshot[item.type])) || {}
      detail.isDefaultValue = true
      if (detail.score_manual && detail.score_manual.value) {
        detail.isDefaultValue = false
      }
      // if (catchScore) {
      //   detail.score_manual = Object.assign(detail.score_manual || {}, {
      //     value: catchScore.score,
      //     updated_at: new Date()
      //   })
      // }
      return {
        ...item,
        ...detail
      }
    })
  }

  const compareData = getDataList(previous.snapshot || {}, 'previous')
  const dataList = getDataList(latest.snapshot || {}, 'latest')

  let [catchEditScore, setCatchEditScore] = useState([])
  const [scoresLegalCheck, serScoresLegalCheck] = useState({})

  const saveBulk = async (catchData) => {
    await HandleSaveScore(catchData || catchEditScore)
    setCatchEditScore([])
  }

  const save = async (type, setToDefaultValue) => {
    if (setToDefaultValue) {
      await saveBulk([
        {
          type,
          score: null,
          graph_type: 'latest'
        },
        {
          type,
          score: null,
          graph_type: 'previous'
        }
      ])
    } else if (catchEditScore.length) {
      await saveBulk()
    }
  }
  const onScoreChangeHandle = (score, type, graph_type) => {
    score = score || null
    let targetIndex = catchEditScore.findIndex(
      (item) => item.type === type && item.graph_type === graph_type
    )
    const scoreLegal = score ? score >= 5 && score <= 99 : true
    const scoreKey = type + graph_type
    scoresLegalCheck[scoreKey] = scoreLegal

    if (targetIndex !== -1) {
      catchEditScore[targetIndex].score = score
    } else {
      catchEditScore.push({
        type,
        graph_type,
        score
      })
    }
    setCatchEditScore(catchEditScore)
    serScoresLegalCheck(scoresLegalCheck)
  }
  const cancelEdit = (type) => {
    if (type) {
      catchEditScore = catchEditScore.filter((item) => item.type !== type)
      setCatchEditScore(catchEditScore)
    } else {
      setCatchEditScore([])
    }
  }

  const commonProps = {
    hideSectionHandle,
    hideSection,
    person,
    dynamicData,
    onScoreChangeHandle,
    cancelEdit,
    updateEditingCount,
    loginRole,
    handleCommendSave,
    arDetail,
    view: 'Draft'
  }

  return arDetail ? (
    <InfiniteScroll
      dataLength={infinityLoadCount}
      next={next}
      hasMore={infinityLoadCount < maxLoadCount}
      loader={
        <h4
          style={{
            paddingBottom: 75,
            fontSize: 24,
            textAlign: 'center',
            color: '#4C6072'
          }}
        >
          Loading...
        </h4>
      }
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      <DraftContainer>
        <Banner person={person} period={arDetail.time_period} />
        <IntroductionSection
          {...commonProps}
          notes={arDetail.notes}
          handleCommendSave={handleCommendSave}
          editabled
        />
        <PersonalInfoSection
          {...{
            person,
            latest_bmi,
            latest_height,
            latest_weight,
            device_info,
            residence
          }}
        />
        <HealthCompassSection
          {...commonProps}
          {...{
            compareData,
            dataList,
            person,
            dynamicData
          }}
        />
        {infinityLoadCount > 1 && (
          <Cardiometabolic
            {...commonProps}
            {...{
              previous,
              latest,
              save,
              handleSave,
              trendMetrics
            }}
          />
        )}

        {infinityLoadCount > 2 && (
          <>
            <BoneHealth
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList,
                save,
                handleSave,
                infinityLoadCount
              }}
            />
            <BodyCompResultSection
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList,
                save,
                handleSave,
                infinityLoadCount
              }}
            />
          </>
        )}

        {infinityLoadCount > 3 && (
          <CognitiveHealth
            {...commonProps}
            {...{
              save,
              dataList,
              compareData,
              ageScopes
            }}
          />
        )}

        {infinityLoadCount > 4 && (
          <Balance
            {...commonProps}
            {...{
              ageScopes,
              compareData,
              dataList,
              save,
              handleSave,
              infinityLoadCount
            }}
          />
        )}

        {infinityLoadCount > 5 && (
          <>
            <MovementQuality
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList,
                save,
                handleSave
              }}
            />
          </>
        )}

        {infinityLoadCount > 6 && (
          <>
            <MuscleSection
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList,
                save,
                handleSave
              }}
            />
          </>
        )}

        {infinityLoadCount > 7 && (
          <Aerobic
            {...commonProps}
            {...{
              ageScopes,
              compareData,
              dataList,
              save,
              handleSave,
              infinityLoadCount
            }}
          />
        )}

        {infinityLoadCount > 7 && (
          <RecommendationSection
            {...commonProps}
            editabled={true}
            arDetail={arDetail}
            onUpdateNotes={handleCommendSave}
            onUpdateReferral={onUpdateReferral}
            updateArDetail={updateArDetail}
          />
        )}
        <Footer>
          <div>
            <Button
              ghost
              type="primary"
              onClick={() => handleSave()}
              disabled={editingCount > 0}
            >
              SAVE AS DRAFT
            </Button>
            <Button
              ghost
              type="primary"
              disabled={editingCount > 0}
              onClick={() => showActivity()}
            >
              ACTIVITY
            </Button>
          </div>
          <div>
            <Button
              ghost
              type="primary"
              onClick={handlePreview}
              disabled={editingCount > 0}
            >
              <ViewIcon />
              PREVIEW
            </Button>
            <Button
              type="primary"
              onClick={handlePublish}
              disabled={editingCount > 0}
            >
              <SimpleUploadIcon />
              PUBLISH
            </Button>
            {/* <Button
            type="primary"
            onClick={handleNotify}
            disabled={!arDetail.published_at}
          >
            <BellIcon />
            NOTIFY CLIENT
          </Button> */}
          </div>
        </Footer>
      </DraftContainer>
    </InfiniteScroll>
  ) : createdFailed ? (
    <ErrorContainer>
      <span className="message">{createdFailed}</span>
      <Button
        type="primary"
        onClick={() => {
          props.history.goBack()
        }}
      >
        Back
      </Button>
    </ErrorContainer>
  ) : (
    <PageLoading />
  )
}
