import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(215, 215, 215, 1);
  &:not(:first-child) {
    padding-top: 64px;
  }
  &:not(:last-child) {
    padding-bottom: 36px;
    border-bottom: 1px solid rgba(215, 215, 215, 1);
  }
  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: rgba(76, 96, 114, 1);
    }
    .btn-section {
      button {
        padding: 0 24px;
        height: 40px;
        &:disabled {
          color: #ffffff;
          opacity: 0.7;
        }

        &.cancel-btn {
          border-color: rgb(38, 67, 130);
          color: rgb(38, 67, 130);
        }
        &.confirm-btn {
          margin-left: 12px;
          background-color: rgb(38, 67, 130);
          &:hover {
            border-color: rgb(38, 67, 130);
          }
        }
      }
    }
  }
  .form-section {
    margin-top: 16px;
    .upload-wrapper {
      padding: 12px 0;
      .upload-btn {
        width: max-content;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        cursor: pointer;
        .tips {
          margin-left: 12px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(38, 67, 130, 1);
        }
      }
      .file-input {
        display: none;
      }
    }
  }
  .preview-section {
    margin-top: 36px;
  }
`
