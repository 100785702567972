import React, { useState } from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
// import { SectionStyle } from '../index.styled'
import { EditPanIcon } from '../../icons'
import EditableText from '../comp/EditableText'
import { ViewIcon } from '../comp/hideWrap'
import ConditionalHideWrap from './ConditionalHidewrap'
// import { EditPanIcon } from '../../../components/icons'
// import EditableText from '../components/EditableText'

const Container = styled.section`
  position: relative;

  & > button {
    position: absolute;
    right: 200px;
    top: 60px;
  }

  .editable-text {
    margin-top: 36px;
  }

  .intro-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
  }
`
const NOTES_FIELDS = {
  INTRODUCTION_NOTE: 'introduction_note'
}

export default function IntroductionSection(props) {
  const [editing, setEditing] = useState(false)
  const { notes, handleCommendSave } = props
  const note = notes.find((n) => n.pillar === NOTES_FIELDS.INTRODUCTION_NOTE)

  return (

    <Container>
      <ConditionalHideWrap
        name="Introduction"
        isSection={true}
        syncStatus={props.hideSectionHandle}
        status={props.hideSection}
        view={props.view}
        condition={
          props.view === 'Draft' || (props.view === 'Preview' && !props.hideSection.includes('Introduction'))
        }
        isIntroduction={true}
      >
    <>
    <div className='intro-container'>
      <h1 className="section-title">Introduction</h1>
      {!editing && props.editabled && (
        <Button type="primary" ghost style={{ marginRight: '100px', marginTop: '4px' }} onClick={() => setEditing(!editing)}>
          <EditPanIcon />
          EDIT
        </Button>
      )}
    </div> 
      <EditableText
        value={(note && note.memo) || ''}
        placeholder={'Please type here.'}
        editing={editing}
        setEditing={setEditing}
        fontStyle={{
          fontSize: '15px',
          fontColor: 'rgba(82, 96, 108, 1)'
        }}
        onSave={(text) => {
          handleCommendSave(text, NOTES_FIELDS.INTRODUCTION_NOTE)
        }}
        className={'editable-text'}
      />
      </>
      </ConditionalHideWrap>
    </Container>
  )
}
