import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { Container } from './index.styled'
import { Button, message, Spin } from 'antd'
import ReferralPreviewCard from '../ReferralPreviewCard'
import BasicForm from '../../../../components/formV4'
import { pattern } from '../../../../utils'
import { ReactComponent as ImageIcon } from '../../../../asserts/icon/client/ic_image.svg'
import Confirm from '../../../../components/UI/confirmModal'
import { uploadReferralImage } from '../../../../api/assessmentReview'

export const FormFields = {
  TITLE: 'title',
  LINK: 'link',
  DESCRIPTION: 'description'
}

const { TITLE, LINK, DESCRIPTION } = FormFields

export const formData = [
  {
    label: 'TITLE',
    dbField: TITLE,
    type: 'input',
    placeholder: 'Please enter title',
    rules: [{ required: true, message: 'Please enter title!' }]
  },
  {
    label: 'LINK',
    dbField: LINK,
    type: 'input',
    placeholder: 'Paste a link here',
    rules: [
      { required: true, message: 'Please enter link!' },
      { pattern: pattern.URL, message: 'Please enter a valid link!' }
    ]
  },
  {
    label: 'DESCRIPTION',
    type: 'input',
    dbField: DESCRIPTION,
    maxLength: 80,
    placeholder: 'Fill in the description here'
  }
]

const UploadImage = forwardRef(
  ({ data, setConfirmModalOpen, onChange }, ref) => {
    const [loading, setLoading] = useState(false)

    const inputRef = useRef(null)

    const handleClick = useCallback(() => {
      if (data.id) {
        inputRef.current.click()
      } else {
        setConfirmModalOpen(true)
      }
    }, [])

    useImperativeHandle(ref, () => {
      return {
        uploadFile: () => {
          inputRef.current.click()
        }
      }
    })

    const handleChange = useCallback(
      async (event) => {
        if (event.target.files && event.target.files[0]) {
          setLoading(true)
          const file = event.target.files[0]
          const formData = new FormData()
          formData.append('file', file)
          const { image } = await uploadReferralImage(file, data.id)
          onChange({ image })
          setLoading(false)
          message.success('Image updated successfully!')
        }
      },
      [data, onChange]
    )

    return (
      <Spin spinning={loading} tip="Loading...">
        <div className="upload-wrapper">
          <div className="upload-btn" onClick={handleClick}>
            <ImageIcon />
            <div className="tips">Click to change image COVER</div>
          </div>

          <input
            ref={inputRef}
            className="file-input"
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleChange}
          />
        </div>
      </Spin>
    )
  }
)

export default function ReferralEditor({
  index,
  data,
  onChange,
  onCancel,
  onSave,
  onUpdateImage
}) {
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  const formRef = useRef(null)
  const uploadImageRef = useRef(null)

  const handleChangeBasicInfo = useCallback(
    async (changeValue, allValues) => {
      onChange(allValues)
    },
    [data, onChange]
  )

  useEffect(() => {
    formRef.current
      .validateFields()
      .then(() => {
        setSubmitEnabled(true)
      })
      .catch(() => {
        setSubmitEnabled(false)
      })
  }, [data])

  const handleSave = useCallback(
    async (needClose = true) => {
      setSaving(true)
      const { id, title, link, description } = data
      const result = await onSave(id, { title, link, description })
      onChange({ ...result, editing: !needClose })
      setSaving(false)
      message.success('Saved successfully!')
    },
    [index, data]
  )

  const handleModalConfirm = useCallback(async () => {
    if (!submitEnabled) {
      message.warn('You have unfilled fields!')
    } else {
      await handleSave(false)
      uploadImageRef.current.uploadFile()
    }
    setConfirmModalOpen(false)
  }, [submitEnabled, handleSave])

  return (
    <Container>
      <div className="header-section">
        <div className="title">{`Article ${index + 1}`}</div>
        <div className="btn-section">
          <Button className="cancel-btn" onClick={() => onCancel(index)}>
            Cancel
          </Button>
          <Button
            className="confirm-btn"
            type="primary"
            disabled={!submitEnabled || saving}
            onClick={handleSave}
          >
            SAVE
          </Button>
        </div>
      </div>
      <div className="form-section">
        <BasicForm
          formData={formData}
          initialValues={{ ...data }}
          onChange={handleChangeBasicInfo}
          wrappedComponentRef={(form) => (formRef.current = form)}
        />
        <UploadImage
          ref={uploadImageRef}
          data={data}
          setConfirmModalOpen={setConfirmModalOpen}
          onChange={onUpdateImage}
        />
      </div>
      <div className="preview-section">
        <ReferralPreviewCard {...data} />
      </div>
      {confirmModalOpen && (
        <Confirm
          modalWidth={600}
          loading={saving}
          onCancel={() => setConfirmModalOpen(false)}
          onConfirm={handleModalConfirm}
          title={'Save Action Item before attaching file?'}
          description={
            'Action Items must be saved before attaching a file or URL.'
          }
        />
      )}
    </Container>
  )
}
