import React, { useState, useEffect } from 'react'
import { Modal, Divider, Button } from 'antd'
import BasicForm from '../../../components/formV4'
import { serviceList } from './index'
import _, { isNumber } from 'lodash'
import moment from 'moment'
import { BookedFor, roleLevel } from '../../../utils/constant'
import { getClient } from '../../../api'

export default function AppointmentsEditor(props) {
  const { cancel, clients } = props
  const [basicForm, setBasicForm] = useState(null)
  const [detailForm, setDetailForm] = useState(null)
  const [editor, setEditor] = useState(null)
  const [basicValues, setBasicValues] = useState({})
  const [detailValues, setDetailValues] = useState({})
  const [sessionPricing, setSessionPricing] = useState(null)

  const basicInfo = (editor) => {
    let fields = [
      {
        type: 'date',
        label: 'date',
        dbField: 'date',
        format: 'MMM DD, YYYY',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }]
      },
      {
        type: 'time',
        label: 'start',
        dbField: 'start_at',
        extraAttr: {
          format: 'h:mm A',
          initialFormat: 'YYYY-MM-DD h:mm A',
          minuteStep: 5,
          showNow: false,
          disabledTime: () => {
            const { end_at } = basicValues
            if (!end_at)
              return { disabledHours: () => [], disabledMinutes: () => [] }
            const format = end_at.includes('M') ? 'h:mm A' : 'hh:mm:ss'
            const endTime = moment(end_at, format)
            const endHour = endTime.hour(),
              endMinute = endTime.minute()
            return {
              disabledHours: () =>
                Array.from(
                  { length: 24 },
                  (_, i) => i > endHour && i
                ).filter((i) => isNumber(i)),
              disabledMinutes: (selectedHour) =>
                selectedHour < endHour
                  ? []
                  : Array.from(
                      { length: 60 },
                      (_, i) => i >= endMinute && i
                    ).filter((i) => isNumber(i))
            }
          }
        },
        colSpan: 6,
        rules: [{ required: true, message: 'This field is required' }]
      },
      {
        type: 'time',
        label: 'end',
        dbField: 'end_at',
        extraAttr: {
          format: 'h:mm A',
          minuteStep: 5,
          initialFormat: 'YYYY-MM-DD h:mm A',
          showNow: false,
          disabledTime: () => {
            const { start_at } = basicValues
            if (!start_at)
              return { disabledHours: () => [], disabledMinutes: () => [] }
            const format = start_at.includes('M') ? 'h:mm A' : 'hh:mm:ss'
            const startTime = moment(start_at, format)
            const startHour = startTime.hour(),
              startMinute = startTime.minute()
            return {
              disabledHours: () =>
                Array.from(
                  { length: 24 },
                  (_, i) => i < startHour && i
                ).filter((i) => isNumber(i)),
              disabledMinutes: (selectedHour) =>
                selectedHour > startHour
                  ? []
                  : Array.from(
                      { length: 60 },
                      (_, i) => i <= startMinute && i
                    ).filter((i) => isNumber(i))
            }
          }
        },
        colSpan: 6,
        rules: [{ required: true, message: 'This field is required' }]
      }
    ]
    if (editor && editor.id) {
    } else {
      fields.push({
        type: 'select',
        label: 'Calendar Type',
        dbField: 'calendar_type',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }],
        options: [
          {
            label: 'Apeiron Training Sessions',
            value: 'Apeiron Training Sessions'
          },
          {
            label: 'Apeiron Client Services Appointments',
            value: 'Apeiron Client Services Appointments'
          }
        ]
      })
    }
    return fields
  }

  const handleClientChange = async (client_id) => {
    try {
      const selectedClient = await getClient(client_id)

      const clientData = Array.isArray(selectedClient.clients)
        ? selectedClient.clients[0]
        : null

      if (
        clientData &&
        clientData.profile &&
        clientData.profile.session_pricing
      ) {
        setSessionPricing(clientData.profile.session_pricing)

        if (
          detailValues.location &&
          basicValues.start_at &&
          basicValues.end_at
        ) {
          const startMoment = moment(basicValues.start_at, 'h:mm A')
          const endMoment = moment(basicValues.end_at, 'h:mm A')

          if (startMoment.isValid() && endMoment.isValid()) {
            const duration = endMoment.diff(startMoment, 'minutes')

            const locationPricing =
              clientData.profile.session_pricing[detailValues.location]

            if (locationPricing) {
              detailForm.setFieldsValue({
                client_pay: (duration / 60) * (locationPricing.client_pay || 0)
              })
            } else {
              console.warn(
                `No client_pay found for location: ${detailValues.location}`
              )
            }
          } else {
            console.warn('Invalid start or end time for duration calculation')
          }
        }
      } else {
        console.warn('No valid session pricing found in client profile')
        setSessionPricing(null)
      }
    } catch (error) {
      console.error('Error fetching client data:', error)
      setSessionPricing(null)
    }
  }

  useEffect(() => {
    if (detailValues.client_id) {
      handleClientChange(detailValues.client_id)
    }
  }, [detailValues.client_id])

  const detailInfo = (editor) => {
    let fields = [
      {
        type: 'input',
        label: 'trainer',
        dbField: 'service_provider_name',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }]
      },
      {
        type: 'select',
        label: 'client',
        dbField: 'client_id',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }],
        extraAttr: {
          showSearch: true,
          placeholder: 'Select a client',
          optionFilterProp: 'children'
        },
        options: clients
          .filter((client) => client.role === roleLevel.User)
          .sort((a, b) => a.full_name.localeCompare(b.full_name))
          .map((client) => ({
            label: (client.profile.nickname || client.first_name) +' '+ client.last_name,
            value: client.id,
            children: (client.profile.nickname || client.first_name) +' '+ client.last_name
          }))
      },
      {
        type: 'select',
        label: 'booked for',
        dbField: 'booked_for',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }],
        options: BookedFor
      },
      {
        type: 'select',
        label: 'type of service',
        dbField: 'service',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }],
        options: (serviceList || []).map((item) => ({
          label: item,
          value: item
        }))
      },
      {
        type: 'number',
        label: 'length of serviice',
        dbField: 'duration',
        disabled: true,
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }]
      },
      {
        type: 'select',
        label: 'location',
        dbField: 'location',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }],
        options: [
          { label: 'Home', value: 'Home' },
          { label: 'ApeironLife', value: 'ApeironLife' },
          { label: 'Virtual', value: 'Virtual' },
          { label: 'Other', value: 'Other' }
        ]
      },
      {
        type: 'input',
        label: 'sessions used',
        dbField: 'sessions_used',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }]
      },
      {
        type: 'input',
        label: 'client pay',
        dbField: 'client_pay',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }]
      },
      {
        type: 'input',
        label: 'appointment NOTES',
        dbField: 'notes'
      }
    ]
    if (editor && editor.id) {
      fields.unshift({
        type: 'input',
        label: 'Appointment',
        dbField: 'summary',
        disabled: true,
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }]
      })
      fields.splice(fields.length - 1, 0, {
        type: 'select',
        label: 'Appointment Status',
        dbField: 'status',
        colSpan: 12,
        rules: [{ required: true, message: 'This field is required' }],
        options: [
          { label: 'Completed', value: 'Completed' },
          { label: 'Canceled', value: 'Canceled' },
          { label: 'Not Started', value: 'Not Started' }
        ]
      })
    }
    return fields
  }

  useEffect(() => {
    if (props.editor.id) {
      const { location, service, start_at, end_at } = props.editor
      const _editor = _.assign(_.cloneDeep(props.editor), {
        location: location,
        service: service,
        start_at: moment(start_at).format('HH:mm:ss'),
        end_at: moment(end_at).format('HH:mm:ss'),
        date: start_at
      })
      setEditor(_editor)
      setBasicValues({
        date: start_at,
        start_at: moment(start_at).format('HH:mm:ss'),
        end_at: moment(end_at).format('HH:mm:ss')
      })
    } else {
      setEditor(props.editor)
    }
  }, [props.editor])
  return (
    <Modal
      open={true}
      onCancel={cancel}
      className="phs-modal client-creation"
      width={880}
      footer={
        <>
          {editor && editor.id && (
            <Button danger onClick={delAction}>
              DELETE
            </Button>
          )}
          <Button type="primary" ghost onClick={cancel}>
            CANCEL
          </Button>
          <Button type="primary" onClick={submit}>
            {editor && editor.id ? 'UPDATE' : 'CREATE'}
          </Button>
        </>
      }
    >
      <h1 className="title">
        {editor && editor.id ? 'Edit' : 'Add'} Appointment
      </h1>
      {editor && (
        <BasicForm
          wrappedComponentRef={(inst) => setBasicForm(inst)}
          initialValues={editor}
          formData={basicInfo(editor)}
          onChange={updateBasicForm}
        />
      )}
      <Divider />
      <h3 style={{ marginBottom: '2em' }}>Appointment details</h3>

      {editor && (
        <BasicForm
          wrappedComponentRef={(inst) => setDetailForm(inst)}
          initialValues={editor}
          formData={detailInfo(editor)}
          onChange={updateDetailForm}
        />
      )}
    </Modal>
  )

  async function delAction() {
    props.delAction(props.editor)
  }

  async function submit() {
    try {
      const [basicInfo, detailInfo] = await Promise.all([
        basicForm.handleSubmit(),
        detailForm.handleSubmit()
      ])
      const { date, start_at, end_at, calendar_type } = basicInfo
      const dateStr = moment(date).format('YYYY-MM-DD')
      const startStr = moment(
        start_at,
        start_at.includes('M') ? 'h:mm:ss A' : 'HH:mm:ss'
      ).format('HH:mm:ss')
      const endStr = moment(
        end_at,
        end_at.includes('M') ? 'h:mm:ss A' : 'HH:mm:ss'
      ).format('HH:mm:ss')

      let params = _.assign(detailInfo, {
        start_at: `${dateStr} ${startStr}`,
        end_at: `${dateStr} ${endStr}`
      })
      if (calendar_type) {
        params.calendar_type = calendar_type
      }
      props.submit(params, props.editor.id)
    } catch (err) {}
  }

  function updateBasicForm(changedFields, allFields) {
    setBasicValues(allFields)

    if (changedFields.start_at || changedFields.end_at) {
      const { start_at, end_at } = allFields
      const duration = moment(end_at, 'h:mm A').diff(
        moment(start_at, 'h:mm A'),
        'minutes'
      )
      const fieldsValue = { duration }

      if (sessionPricing && detailValues.location) {
        const locationPricing = sessionPricing[detailValues.location]
        if (locationPricing) {
          fieldsValue.client_pay = (duration / 60) * (locationPricing.client_pay || 0)
        } else if (duration > 0) {
          fieldsValue.client_pay = (duration / 15) * 50
        }
      } else if (duration > 0) {
        fieldsValue.client_pay = (duration / 15) * 50
      }

      if (detailValues.location) {
        fieldsValue.sessions_used = calcSessionsUserd(
          detailValues.location,
          duration
        )
      }

      detailForm.setFieldsValue(fieldsValue)
    }
  }

  function updateDetailForm(changedFields, allFields) {
    setDetailValues(allFields)

    if (changedFields.location && basicValues.start_at && basicValues.end_at) {
      const location = changedFields.location
      const duration = moment(basicValues.end_at, 'h:mm A').diff(
        moment(basicValues.start_at, 'h:mm A'),
        'minutes'
      )

      const fieldsValue = {}

      if (sessionPricing && sessionPricing[location]) {
        const locationPricing = sessionPricing[location]
          fieldsValue.client_pay = (duration / 60) * (locationPricing.client_pay || 0 )
      }

      fieldsValue.sessions_used = calcSessionsUserd(location, duration)
      detailForm.setFieldsValue(fieldsValue)
    }
  }

  function calcSessionsUserd(location, duration) {
    if (sessionPricing && sessionPricing[location]) {
      const locationPricing = sessionPricing[location]
        return (duration / 60) * (locationPricing.sessions || 0)
    }

    let sessions_used = 0
    switch (location) {
      case 'Home':
        if (duration <= 30) {
          sessions_used = 0.7
        } else {
          sessions_used = 0.7 + ((duration - 30) / 15) * 0.25
        }
        break
      case 'Other':
        sessions_used = 1.0
        break
      default:
        sessions_used = (duration / 15) * 0.25
        break
    }
    return sessions_used
  }
}
