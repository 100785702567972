import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  height: 216px;
  border-radius: 12px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: #f9f9f9;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  .left-section {
    padding: 40px;
    max-width: calc(100% - 308px);
    line-height: 24px;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: rgba(38, 67, 130, 1);
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .description {
      margin-top: 8px;
      font-size: 15px;
      font-weight: 500;
      color: rgba(112, 128, 142, 1);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .link {
      margin-top: 8px;
      font-size: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;
      a {
        margin-left: 8px;
        max-width: calc(100% - 24px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .right-section {
    width: 308px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 100%;
    }
  }
  > button {
    position: absolute;
    top: 12px;
    right: 12px;
    display: none;
    padding: 0;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    background: #eff1f3;
    &:hover {
      background: #eff1f3;
    }
  }
  &:hover > button {
    display: flex;
  }
`
