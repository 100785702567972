import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Link } from 'react-scroll'
import { Menu, Badge } from 'antd'
import { loadUser } from '../../../utils/storage'
import { userType } from '../../../utils/constant'

import { styles } from './index.sass'

const { SubMenu, Item } = Menu
export const datasetTypes = [
  {
    path: 'vitals',
    key: 'vitals',
    menuKey: 'vitals',
    originKey: 'body_comp',
    menuTitle: 'Vitals',
    permission: ['Expert', 'User', 'InternalAdmin'],
    hasSubMenu: true
  },
  {
    path: 'body-comp',
    key: 'bodyCom',
    menuKey: 'body_comp',
    menuTitle: 'Body Composition',
    permission: ['Expert', 'User', 'InternalAdmin'],
    hasSubMenu: true
  },
  {
    path: 'bone-density',
    key: 'boneDensity',
    menuKey: 'bone_density',
    originKey: 'body_comp',
    menuTitle: 'Bone Density',
    permission: ['Expert', 'User', 'InternalAdmin'],
    hasSubMenu: true
  },
  {
    path: 'movement-quality',
    key: 'movementQuality',
    menuTitle: 'Movement Quality & Balance',
    menuKey: 'movement_quality',
    originKey: 'physical_assessment',
    permission: ['Expert', 'User', 'InternalAdmin'],
    hasSubMenu: true
  },

  {
    path: 'muscle-fitness',
    key: 'muscleFitness',
    menuKey: 'muscle_fitness',
    originKey: 'physical_assessment',
    menuTitle: 'Muscle Fitness', //'Initial 360 Physical Evaluation',
    permission: ['Expert', 'User', 'InternalAdmin'],
    hasSubMenu: true
  },
  {
    path: 'aerobic-fitness',
    key: 'aerobicFitness',
    menuKey: 'aerobic_fitness',
    originKey: 'cardiorespiratory',
    menuTitle: 'Aerobic Fitness', //'Cardiovascular Fitness',
    permission: ['Expert', 'User', 'InternalAdmin'],
    hasSubMenu: true
  },
  {
    path: 'cognitive-health',
    key: 'cognitive_health',
    menuKey: 'cognitive_health',
    menuTitle: 'Cognitive Health',
    permission: ['Expert', 'User', 'InternalAdmin'],
    hasSubMenu: true
  },
  {
    path: 'blood-test',
    key: 'blood_test',
    menuKey: 'bio_marker',
    menuTitle: 'Blood Panel',
    permission: ['Expert', 'User', 'InternalAdmin'],
    hasSubMenu: true
  }
]

class ClientSlideMenu extends Component {
  static propTypes = {
    location: PropTypes.object,
    loginRole: PropTypes.string,
    datasetCount: PropTypes.array
  }

  state = {
    isCollaps: false,
    menuList: getMenuList(this.props.loginRole, this.props.datasetCount)
  }

  handleClick = (menu) => {
    const {
      hrefs,
      history,
      loginRole,
      location: { pathname },
      slideMenu: { groups }
    } = this.props
    if (menu.keyPath) {
      this.setState({
        // isCollaps: !this.state.isCollaps,
        selectedKeys: menu.keyPath
      })
    }
    const href = hrefs[menu.key]
    if (href) {
      history && history.push(href)
    } else {
      const [page, category] = menu.key.split('+')
      const targetMenu = this.state.menuList.find((item) => item.path === page)
      const type = targetMenu.menuKey
      const target = groups[type].categories.find(
        ({ name }) => name.replace(/ /g, '_') === category
      )
      if (target) {
        if (loginRole === 'User') {
          history.push(`/app/dataset/${page}/${target.name}`)
        } else if (loginRole === 'Public') {
          const pathArr = pathname.split('/')
          pathArr.splice(2, 1, page)
          pathArr.splice(4, 1, target.name)
          history.push(pathArr.join('/'))
        } else {
          const path = pathname.split('/detail')[0]
          history.push(`${path}/detail/${page}/${target.name}`)
        }
      }
    }
  }

  subMenuClickHandle = (menu) => {
    if (this.state.isInitial) {
      this.setState({ isInitial: false })
    }
    const {
      hrefs,
      history,
      location: { pathname }
    } = this.props
    let isCollaps = this.state.isCollaps
    if (menu.keys) {
      let selectedKeys = menu.keys.concat(this.state.selectedKeys || [])
      if (_.includes(this.state.selectedKeys, menu.keys[0])) {
        if (this.state.selectedKeys.length > 2) {
          selectedKeys = this.state.selectedKeys.filter(
            (item) => item !== menu.keys[0]
          )
        } else {
          selectedKeys = this.state.selectedKeys
          isCollaps = !isCollaps
        }
      }
      this.setState({
        isCollaps,
        selectedKeys
      })
    } else {
      const href = hrefs[menu.key]
      if (href && href !== pathname) {
        history && history.push(href)
        isCollaps = true
      } else if (this.state.selectedKeys) {
        isCollaps = true
      } else {
        isCollaps = !isCollaps
      }
      this.setState({
        isCollaps,
        selectedKeys: null
      })
    }
  }

  getDefaultKeys = () => {
    const {
      location: { pathname }
    } = this.props
    const pathArr = pathname.split('/')
    let target = this.state.menuList.find(
      (item) =>
        _.includes(pathArr, item.path) ||
        _.get(item, 'subMenu', []).find((subItem) =>
          _.includes(pathArr, subItem.path)
        )
    )
    if (target && target.subMenu) {
      target = target.subMenu.find((subItem) =>
        _.includes(pathArr, subItem.path)
      )
      return target.keys
    } else if (target && target.key) {
      return [target.key]
    } else {
      return ['profile', 'overview']
    }
  }

  componentDidMount() {
    const selectedKeys = this.getDefaultKeys()
    this.setState({ selectedKeys, isCollaps: true })
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname }
    } = this.props

    if (pathname !== prevProps.location.pathname) {
      const selectedKeys = this.getDefaultKeys()
      if (
        this.state.selectedKeys &&
        !_.includes(this.state.selectedKeys, selectedKeys[0])
      ) {
        this.setState({ selectedKeys, isCollaps: true })
      }
    }
  }

  getSelectedKeys = (path) => {
    const { isCollaps, selectedKeys } = this.state
    const {
      slideMenu: { groups, currentCategory }
    } = this.props
    const pathArr = path.split('/')
    let keys = []
    let target = this.state.menuList.find((item) =>
      _.includes(pathArr, item.path)
    )
    if (isCollaps) {
      if (selectedKeys) {
        keys = selectedKeys
      } else if (target) {
        keys.push(target.key)
        if (target.subMenu) {
          const subTarget = target.subMenu.find((item) =>
            pathArr.find((p) => p === item.path)
          )
          if (subTarget) {
            keys.push(subTarget.key)
          }
        }
      }
      if (target && target.hasSubMenu) {
        const subList = _.get(groups, `${target.menuKey}.categories`, []).map(
          (item) => item.name
        )
        if (_.includes(subList, currentCategory)) {
          if (keys.length > 1) {
            keys = keys.filter((key) => !_.includes(key, target.path))
          }
          keys.push(`${target.path}+${currentCategory.replace(/ /g, '_')}`)
        }
      }
    }
    if (!keys.length) {
      keys.push('profile')
    }

    return keys
  }

  typeLink = (type) => {
    const {
      location: { pathname },
      hrefs,
      history
    } = this.props

    const href = hrefs[type]
    if (!pathname.match(href)) {
      history && history.push(href)
    }
  }

  renderSubmenu(targetMenu) {
    const {
      slideMenu: { groups }
    } = this.props
    if (targetMenu.subMenu) {
      return (
        <SubMenu
          key={targetMenu.key}
          title={targetMenu.menuTitle}
          onTitleClick={() => this.subMenuClickHandle(targetMenu)}
        >
          {targetMenu.subMenu.map((item) => (
            <Item
              key={item.key}
              className={item.className}
              disabled={item.disabled}
            >
              <span> {item.menuTitle}</span>
            </Item>
          ))}
        </SubMenu>
      )
    }
    return (
      <SubMenu
        key={targetMenu.key}
        disabled={disabled(targetMenu.menuKey, null, this.props)}
        title={targetMenu.menuTitle}
        onTitleClick={() => this.subMenuClickHandle(targetMenu)}
      >
        {groups &&
          groups[targetMenu.menuKey].categories.map((item) => (
            <Item
              key={`${targetMenu.path}+${item.name.replace(/ /g, '_')}`}
              disabled={disabled(targetMenu.menuKey, item, this.props)}
            >
              <Link to={`category_${item.name}`}>{item.name}</Link>
            </Item>
          ))}
      </SubMenu>
    )
  }

  render() {
    const {
      location: { pathname },
      client,
      loginRole
    } = this.props
    const defaultKeys = this.getSelectedKeys(pathname)
    const { menuList } = this.state
    const loggedInUser = loadUser()
    const isTransactionScreenAvailable = loggedInUser && loggedInUser.profile && loggedInUser.profile.flags &&loggedInUser.profile.flags.showTransactionScreen

    return (
      <div className={`${styles} ${loginRole}`}>
        <Menu
          onClick={this.handleClick}
          selectedKeys={defaultKeys}
          openKeys={defaultKeys}
          mode="inline"
        >
          {menuList.map((item) => {
            if (item.key === 'message') {
              return (
                <Item
                  key={item.key}
                  className={item.className}
                  disabled={item.disabled}
                >
                  {item.menuTitle}
                  <Badge
                    count={client ? client.unreadCount : 0}
                    overflowCount={99}
                    style={{
                      marginLeft: '10px'
                    }}
                  />
                </Item>
              )
            }

            if (item.key === 'transactions' && loginRole === 'User' && !isTransactionScreenAvailable) {
              return null
            }

            return item.hasSubMenu ? (
              this.renderSubmenu(item)
            ) : (
              <Item
                key={item.key}
                className={item.className}
                disabled={item.disabled}
              >
                {item.menuTitle}
              </Item>
            )
          })}
        </Menu>
      </div>
    )
  }
}

export default connect(
  ({ slideMenu, client }) => ({ slideMenu, client }),
  null
)(ClientSlideMenu)

function disabled(key, category, props) {
  const { datasetCount, loginRole, fileAvailableTypes } = props
  const user = loadUser()
  const isPartner = user.profile.account_type === userType.Partners
  if (
    !loginRole ||
    loginRole === 'Expert' ||
    loginRole === 'InternalAdmin' ||
    isPartner
  )
    return false
  if (!datasetCount) return true
  const target = datasetCount.find((item) => item.group === key)
  if (target) {
    if (target.count > 0 && target.category_ids.length > 0) {
      if (category) {
        const subIds = category.subcategory_ids
        subIds.push(category.id)
        for (const subid of subIds) {
          for (const category_id of target.category_ids) {
            if (subid === category_id) {
              return false
            }
          }
        }
        return true
      }
      return false
    } else {
      return fileAvailableTypes && !fileAvailableTypes.includes(key)
    }
  } else {
    return category === undefined
      ? fileAvailableTypes && !fileAvailableTypes.includes(key)
      : true
  }
}

function getMenuList(loginRole, datasetCount) {
  const menuList = [
    // {
    //   key: 'home',
    //   disabled: true,
    //   className: 'home',
    //   menuTitle: 'HOME',
    //   permission: ['Expert', 'User', 'InternalAdmin']
    // },
    {
      path: 'profile',
      key: 'profile',
      menuTitle: 'Overview',
      permission: ['Service_Provider']
    },
    {
      path: 'profile',
      key: 'profile',
      keys: ['profile', 'overview'],
      permission: ['Expert', 'User', 'InternalAdmin'],
      menuTitle: 'Home'
    },
    {
      path: 'timeline',
      key: 'timeline',
      permission: ['Expert', 'InternalAdmin'],
      menuTitle: 'Timeline'
    },
    {
      path: 'questionnaire',
      key: 'questionnaire',
      // keys: ['questionnaire', ''],
      menuTitle: 'Questionnaire',
      permission: ['Expert', 'User', 'InternalAdmin']
    },
    {
      path: 'message',
      key: 'message',
      menuTitle: 'Chat',
      permission: ['User', 'Expert', 'Service_Provider', 'InternalAdmin']
    },
    {
      path: 'notes',
      key: 'notes',
      menuTitle: 'Notes',
      permission: ['Expert', 'Service_Provider', 'InternalAdmin']
    },
    {
      path: 'support-team',
      key: 'support_team',
      menuTitle: 'Support Team',
      permission: ['User', 'Expert', 'Service_Provider', 'InternalAdmin']
    },
    {
      key: 'program',
      path: 'program',
      menuTitle: 'Program (Archive)',
      permission: ['Expert', 'Service_Provider', 'InternalAdmin']
    },
    {
      path: 'trends', //'goals',
      key: 'trends', //'micro_goals',
      menuTitle: 'Trends',
      permission: ['Expert', 'User', 'InternalAdmin']
    },
    {
      path: 'setting',
      key: 'setting',
      menuTitle: 'Profile & Settings',
      permission: ['User', 'Expert', 'InternalAdmin']
    },
    {
      key: 'email',
      path: 'summary-email',
      menuTitle: 'Weekly Insights',
      permission: ['Expert', 'InternalAdmin', 'User', 'Service_Provider']
    },
    {
      path: 'resource',
      key: 'resource',
      menuTitle: 'Resources',
      permission: ['User']
    },
    {
      path: 'membership',
      key: 'membership',
      menuTitle: 'Membership & Pricing',
      permission: ['User']
    },
    {
      key: 'file',
      path: 'file',
      menuTitle: 'Files',
      isPlaceholder: true,
      permission: ['Expert', 'InternalAdmin', 'User', 'Service_Provider']
    },
    {
      key: 'transactions',
      path: 'transactions',
      menuTitle: 'Service Sessions',
      permission: ['Expert', 'InternalAdmin', 'User', 'Service_Provider']
    },
    {
      key: 'assessment',
      className: 'assessment',
      disabled: true,
      menuTitle: 'Result',
      permission: ['User', 'Expert', 'InternalAdmin'],
      style: {
        color: `rgba(50, 63, 74, 0.8) ${
          !(datasetCount && datasetCount.length > 0) ? '!important' : ''
        }`
      }
    },
    {
      // path: 'ass-report',
      key: 'health_compass', //'ass_report',
      keys: ['health_compass'],
      menuTitle: '360 Health Compass', //'360 Review & Milestone Planning',
      permission: ['Expert', 'User', 'Service_Provider', 'InternalAdmin'],
      hasSubMenu: true,
      subMenu: [
        {
          path: 'export-data',
          key: 'export_data',
          keys: ['export_data', 'health_compass'],
          menuTitle: 'Export Data'
        },
        {
          path: 'health-score',
          key: 'health_score',
          keys: ['health_score', 'health_compass'],
          menuTitle: 'Health Compass'
        },
        {
          path: 'ass-report',
          key: 'ass_report',
          keys: ['ass_report', 'health_compass'],
          menuTitle: 'Assessment Review'
        }
      ]
    },
    {
      path: 'nutrition-score',
      key: 'nutrition_score',
      menuTitle: 'Nutrition Signals Report',
      permission: ['Expert', 'User', 'InternalAdmin']
    },
    {
      path: 'nutrition-trends',
      key: 'nutrition_trends',
      menuTitle: 'Nutrition Signals Trends',
      permission: ['Expert', 'InternalAdmin']
    },
    {
      path: 'microbiome-analysis',
      key: 'microbiome_analysis',
      menuTitle: 'Microbiome Analysis',
      permission: ['Expert', 'User', 'Service_Provider', 'InternalAdmin']
    },
    //adjust end
    ...datasetTypes
    // {
    //   path: 'life-style',
    //   key: 'lifestyle',
    //   menuKey: 'lifestyle_assessment',
    //   menuTitle: 'Lifestyle',
    //   permission: ['Expert', 'User', 'InternalAdmin'],
    //   hasSubMenu: true
    // }
  ]
  return menuList.filter((item) => _.includes(item.permission, loginRole))
}
