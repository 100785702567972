import React, { useEffect } from 'react'
import { Modal, Form, DatePicker, Input, Select, Button } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import moment from 'moment'

export default function TransactionModal({
  isVisible,
  isEditMode,
  modalData,
  setModalData,
  transactionReasons,
  onClose,
  onSubmit
}) {

  useEffect(() => {
    if (!isEditMode && isVisible && !modalData.transactionType) {
      setModalData({ ...modalData, transactionType: 'credit' })
    }
  }, [isEditMode, isVisible, modalData, setModalData])

  const preventNegative = (e) => {
    const char = String.fromCharCode(e.which)
    if (!/[0-9.]/.test(char) && e.which !== 8 && e.which !== 46) {
      e.preventDefault()
    }
  }

  const restrictToTwoDecimals = (value) => {
    const regex = /^\d*(\.\d{0,2})?$/
    return regex.test(value)
  }

  return (
    <Modal
      width={410}
      title={
        <span className="create-modal-title">
          {isEditMode ? 'Edit Transaction' : 'Create a Transaction'}
        </span>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form layout="vertical">
        <div className="transaction-type">
          <Button
            className={
              modalData.transactionType === 'credit'
                ? 'credit-value'
                : 'credit-filter'
            }
            onClick={() =>
              setModalData({ ...modalData, transactionType: 'credit' })
            }
          >
            {modalData.transactionType === 'credit' && <CheckCircleFilled />}
            CREDIT
          </Button>
          <Button
            className={
              modalData.transactionType === 'debit'
                ? 'debit-value'
                : 'debit-filter'
            }
            onClick={() =>
              setModalData({ ...modalData, transactionType: 'debit' })
            }
          >
            {modalData.transactionType === 'debit' && <CheckCircleFilled />}
            DEBIT
          </Button>
        </div>

        <Form.Item label="TRANSACTION DATE" required>
          <DatePicker
            value={
              modalData.transactionDate
                ? moment(modalData.transactionDate)
                : null
            }
            onChange={(date) =>
              setModalData({ ...modalData, transactionDate: date })
            }
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item label="SESSIONS" required>
          <Input
            type="number"
            value={modalData.sessions}
            style={{ height: '45px' }}
            onWheel={(e) => e.target.blur()}
            onKeyPress={(e) => preventNegative(e)}
            onChange={(e) => {
              const value = e.target.value
              if (restrictToTwoDecimals(value)) {
                setModalData({ ...modalData, sessions: value })
              }
            }}
          />
        </Form.Item>

        <Form.Item label="AMOUNT" required>
          <Input
            type="number"
            value={modalData.amount}
            style={{ height: '45px' }}
            onKeyPress={(e) => preventNegative(e)}
            onChange={(e) => {
              const value = e.target.value
              if (restrictToTwoDecimals(value)) {
                setModalData({ ...modalData, amount: value })
              }
            }}
          />
        </Form.Item>

        <Form.Item label="REASON" required>
          <Select
            value={modalData.reason}
            onChange={(value) => setModalData({ ...modalData, reason: value })}
          >
            {transactionReasons.map((reason) => (
              <Select.Option key={reason} value={reason}>
                {reason}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="NOTES">
          <Input.TextArea
            rows={4}
            value={modalData.notes}
            onChange={(e) =>
              setModalData({ ...modalData, notes: e.target.value })
            }
          />
        </Form.Item>

        <div className="modal-footer">
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={onSubmit}>
            {isEditMode ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
