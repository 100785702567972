import React from 'react'
import { get } from 'lodash'
import { HSDSpiderGraph } from './HSDSpiderGraph'
import { HSDSpiderGraphTrend } from './HSDSpiderGraphTrend'

export const OFFSET_NAME_COMPARE_Y = [-25, -22, -10, 10, 10, 10, -10, -22]
export const OFFSET_SORCE_COMPARE_Y = [-10, -7, 5, 25, 25, 25, 5, -7]
export const SCORE_X = [0, -50, -40, 0, 0, 50, 60]
export const SCORE_L = [-10, -60, -50, -10, -10, 40, 50]
export const SCORE_P = [10, -40, -30, 10, 10, 60, 70]
export const SCORE_SEPERATOR = [0, -50, -40, 0, 0, 50, 60]
export const LABEL_SCORE_ANCHOR = ['middle', 'middle', 'middle', 'middle', 'middle', 'middle', 'middle', 'middle']
export const LABEL_TEXT_ANCHOR = ['middle', 'middle', 'middle', 'middle', 'middle', 'middle', 'middle', 'middle']
export const SCORE_CLASSIFICATION_X = [-28, -78, -68, -16, -20, 30, 40]
export const getScore = (domain) => get(domain, 'score_manual.value') || get(domain, 'score_auto')

export const hasSufficientPreviousData = (compareData) => {
    return compareData.filter((item) => getScore(item) !== null && getScore(item) > 0).length > 1
}

export function HSDScoreConfig(score) {
    if (score >= 90) {
        return {
            color: '#5EA4BA',
            text: 'Exceptional'
        }
    } else if (score >= 80) {
        return {
            color: '#50C878',
            text: 'Very Good'
        }
    } else if (score >= 60) {
        return {
            color: '#7BAD2D',
            text: 'Above Average'
        }
    } else if (score >= 40) {
        return {
            color: '#FDB51C',
            text: 'Average'
        }
    } else if (score >= 20) {
        return {
            color: '#FC5108',
            text: 'Below Average'
        }
    } else {
        return {
            color: '#D5001C',
            text: 'Needs Attention'
        }
    }
}

export default function HSDContainer({ latestData, previousData }) {
    const shouldRenderTrendGraph = hasSufficientPreviousData(previousData)

    return (
        <div>
            {shouldRenderTrendGraph ? (
                <HSDSpiderGraphTrend latestData={latestData} previousData={previousData} />
            ) : (
                    <HSDSpiderGraph latestData={latestData} />
            )}
        </div>
    )
}
