import React from 'react'
import Learning1 from '../../../asserts/images/placeholder/learning1.png'
import Learning2 from '../../../asserts/images/placeholder/learning2.png'
import Learning3 from '../../../asserts/images/placeholder/learning3.png'
// import { ReactComponent as LinkIcon } from '../../../asserts/icon/client/physical-assessment/ic_link.svg'
import './Resource.css'
import HideWrap from '../../../components/assessmentReview/comp/hideWrap'
import ConditionalHideWrap from './ConditionalHidewrap'

function ResourceSection({ view, hideSection, hideSectionHandle }) {
  const dataList = [
    {
      type: 'Exercise',
      title: 'Exercise',
      readTime: '10 mins read',
      thumbUrl: Learning2,
      link:
        'https://public-source.s3-us-west-2.amazonaws.com/learning_center/Apeiron+Life+Foundation+Plan+-+Exercise.pdf'
    },
    {
      type: 'Rejuvenation',
      title: 'Sleep',
      readTime: '8 mins read',
      thumbUrl: Learning3,
      link:
        'https://public-source.s3-us-west-2.amazonaws.com/learning_center/Apeiron+Life+Foundation+Plan+-+Sleep.pdf'
    },
    {
      type: 'Nutrition',
      title: 'Nutrition',
      readTime: '5 mins read',
      thumbUrl: Learning1,
      link:
        'https://public-source.s3-us-west-2.amazonaws.com/learning_center/Apeiron+Life+Foundation+Plan+-+Nutrition.pdf'
    }
  ]

  return (
    <div className="referrals-section">
      <ConditionalHideWrap
        name="Resources"
        title="Resources"
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
        condition={
          view === 'Draft' || (view === 'Preview' && !hideSection.includes('Resources'))
        }
      >
        <div className="top-section">
          <div className="subtitle">Resources</div>
        </div>
        <div className="bottom-section">
          {dataList.map((item, index) => (
            <ConditionalHideWrap
              key={index}
              name={item.title}
              title={item.title}
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
              isIndividuals={true}
              condition={
                view === 'Draft' || (view === 'Preview' && !hideSection.includes(`${item.title}`))
              }
            >
              <div className="resource-wrapper">
                <div className="section-left">
                  <div className="resource-title">{item.title}</div>
                  {item.readTime && (
                    <div className="resource-description">{item.readTime}</div>
                  )}
                  {item.link && (
                    <div className="resource-link-wrapper">
                      {/* <LinkIcon /> */}
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="resource-link"
                      >
                        {item.link}
                      </a>
                    </div>
                  )}
                </div>
                <div className="section-right">
                  {item.thumbUrl && <img src={item.thumbUrl} alt="Cover" />}
                </div>
              </div>
            </ConditionalHideWrap>
          ))}
        </div>
      </ConditionalHideWrap>
    </div>
  )
}

export default ResourceSection
