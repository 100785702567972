import React, { useMemo } from 'react'
import { Container } from './index.styled'
import { ReactComponent as LinkIcon } from '../../../../asserts/icon/client/ic_link.svg'
import classNames from 'classnames'
import { ReactComponent as EditIcon } from '../../../../asserts/icon/client/ic_edit.svg'
import { Button } from 'antd'

export default function ReferralPreviewCard({
  className = '',
  editabled,
  title,
  link,
  description,
  image,
  editing,
  onEdit
}) {
  const imagrUrl = useMemo(() => (image && image.cloudfront_url) || '', [
    image
  ])
  return (
    <Container className={classNames(className)}>
      <div className="left-section">
        <div className="title">{title}</div>
        {description && <div className="description">{description}</div>}
        {link && (
          <div className="link">
            <LinkIcon />
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          </div>
        )}
      </div>
      <div className="right-section">
        {imagrUrl && <img src={imagrUrl} alt="Cover" />}
      </div>

      {!editing && editabled && (
        <Button type="text" onClick={() => onEdit({ editing: true })}>
          <EditIcon className="icon" />
        </Button>
      )}
    </Container>
  )
}
