import { Button } from 'antd'
import { ReactComponent as EditIcon } from '../../../asserts/icon/client/ic_edit.svg'
import React, { useState } from 'react'
// import TextareaEditor from '../../../components/TextareaEditor'
import EditableText from '../comp/EditableText'

export default function HighestPriorityItem({
  editabled,
  field,
  title,
  placeholder,
  value,
  onChange
}) {
  const [editing, setEditing] = useState(false)
  return (
    <div className="highest-priority-item">
      <div className="title-section">
        <div className="subtitle">{title}</div>

        {!editing && editabled && (
          <Button type="text" onClick={() => setEditing(true)}>
            <EditIcon className="icon" />
          </Button>
        )}
      </div>
      <EditableText
        simpleMode
        className={'note'}
        value={value}
        editing={editing}
        setEditing={setEditing}
        onSave={(v) => onChange(v, field)}
      />
      {/* <TextareaEditor
        editing={editing}
        placeholder={editabled ? placeholder : ''}
        onClose={() => setEditing(false)}
        value={value}
        onChange={(v) => onChange(v, field)}
      /> */}
    </div>
  )
}
