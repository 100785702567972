import React from 'react'
import _, { get } from 'lodash'
import styled from 'styled-components'
import classNames from 'classnames'
import { Col, Divider, Row, Space } from 'antd'
import { VictoryPie } from 'victory'
import { FlexBox } from '../../UI/FlexBox'
import HSDContainer from '../comp/HSDContainer'
import { Circle } from '../comp/health'
import HideWrap from '../comp/hideWrap'
import ConditionalHideWrap from './ConditionalHidewrap'
const Container = styled.section`
  .note {
    flex-grow: 1;
    margin-bottom: 60px;
  }

  button {
    margin-left: 40px;
  }

  .graph-container {
    margin-top: 20px;
    padding: 30px;
  }

  .legend-header {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    padding-right: 100px;
    color: #223064;
  }

  .legend-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .legend-wrapper {
    text-align: right;
    margin-top: 20px;
    width: 200px;
    margin-left: 800px;
    padding: 10px;
    background-color: #f9f9f9;
  }

  .legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`

export default function HealthCompassSection(props) {
  const { dataList, dynamicData, compareData} = props
  return (
    <Container>
      <h1 className="section-title">Health Compass</h1>
      <div className='legend-wrapper'>
        <h4 className="legend-header">Legend</h4>
        <div className='legend-container'>
          <span className='legend-item'>
            <Circle fillOpacity={1} fill="#5EA4BA" stroke="#5EA4BA" />
            LATEST
          </span>
          <span className='legend-item'>
            <Circle fillOpacity={1} fill="#474747" stroke="#474747" />
            PREVIOUS
          </span>
        </div>

      </div>

      <div className="graph-container">
        <HSDContainer latestData={dataList} previousData={compareData} />
      </div>
      <ScoreBreakdown
        hideSection={props.hideSection}
        hideSectionHandle={props.hideSectionHandle}
        view={props.view}
        {...get(dynamicData, 'lifeStyleSummary.lifestyleScore')}
      />
    </Container>
  )
}

const ScoreBreakdownContainer = styled.div`
  .qqt-card {
    border: 1px solid rgba(227, 230, 234, 1);
    background-color: rgba(250, 250, 250, 1);
    padding: 20px 40px 40px;
    .pillar-status {
      width: 130px;
      .pillar-name-label {
        font-size: 20px;
        margin-bottom: 8px;
        margin-top: -12px;
        color: rgba(50, 63, 74, 1);
      }
      .pillar-status-label {
        font-size: 15px;
        padding: 5px 13px;
        color: white;
        border-radius: 6px;
        background-color: lightgray;
        &.fair {
          background-color: rgba(245, 186, 64, 1);
        }
        &.optimal {
          background-color: rgba(64, 196, 124, 1);
        }
        &.poor {
          background-color: rgba(255, 107, 0, 1);
        }
      }
    }
    .pie {
      width: 130px;
      position: relative;
      .pillar-score-label {
        color: rgba(50, 63, 74, 1);
        font-size: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .ant-divider-horizontal {
      margin-top: 0px;
    }
    .qqt-title-label {
      color: rgba(76, 96, 114, 1);
      font-size: 15px;
    }
    .qqt-status-label {
      font-size: 15px;
      font-weight: 600;

      &.fair {
        color: rgba(245, 186, 64, 1);
      }
      &.optimal {
        color: rgba(64, 196, 124, 1);
      }
      &.poor {
        color: rgba(255, 107, 0, 1);
      }
    }
  }
`

const ScoreBreakdown = (props) => {
  const level = {
    1: 'poor',
    2: 'fair',
    3: 'optimal'
  }

  const pillars = ['exercise', 'nutrition', 'sleep']

  return (
    <ConditionalHideWrap
      name="Lifestyle Score"
      syncStatus={props.hideSectionHandle}
      status={props.hideSection}
      view={props.view}
      isLifestyleScore={true}
      condition={
        props.view === 'Draft' || (props.view === 'Preview' && !props.hideSection.includes('Lifestyle Score'))
      }
    >
    <ScoreBreakdownContainer>
      <h2 className="ar-section-group-title">Lifestyle Score</h2>
      <Row gutter={24} style={{ marginTop: 36 }}>
        {pillars.map((pillar, index) => {
          const pillarScore = get(props, `${pillar}.score.total`)
          const pillarLevel = get(props, `${pillar}.level.total`)
          const quantity = get(props, `${pillar}.level.quantity`)
          const quality = get(props, `${pillar}.level.quality`)
          const timing = get(props, `${pillar}.level.timing`)
          return (
            <Col span={8} key={index}>
              <div className="qqt-card">
                <FlexBox justifyContent="space-between" alignItems="center">
                  <div className="pillar-status">
                    <div className="pillar-name-label">
                      {_.capitalize(pillar)}
                    </div>
                    <span
                      className={classNames(
                        'pillar-status-label',
                        pillarLevel && level[pillarLevel]
                      )}
                    >
                      {pillarLevel ? _.capitalize(level[pillarLevel]) : '-'}
                    </span>
                  </div>
                  <div className="pie">
                    <VictoryPie
                      innerRadius={110}
                      data={[quantity, quality, timing].map((l) => {
                        switch (l) {
                          case 1:
                            return { x: 'AT RISK', y: 1, color: '#FF6B00' }
                          case 2:
                            return { x: 'CONCERN', y: 1, color: '#F5BA40' }
                          case 3:
                            return { x: 'OPTIMAL', y: 1, color: '#40C47C' }
                          default:
                            return { x: 'N/A', y: 1, color: 'lightgray' }
                        }
                      })}
                      style={{
                        labels: { fill: 'transparent' },
                        data: {
                          fill: (datum) => datum.color
                        }
                      }}
                    />
                    <div className={classNames('pillar-score-label')}>
                      {pillarScore || '--'}
                    </div>
                  </div>
                </FlexBox>
                <Divider />
                <Space direction="vertical" size={12} style={{ width: '100%' }}>
                  <FlexBox justifyContent="space-between">
                    <span className="qqt-title-label">Quantity</span>
                    <span
                      className={classNames(
                        'qqt-status-label',
                        (quantity && level[quantity]) || ''
                      )}
                    >
                      {_.capitalize(quantity && level[quantity]) || '-'}
                    </span>
                  </FlexBox>
                  <FlexBox justifyContent="space-between">
                    <span className="qqt-title-label">Quality</span>
                    <span
                      className={classNames(
                        'qqt-status-label',
                        (quality && level[quality]) || ''
                      )}
                    >
                      {_.capitalize(quality && level[quality]) || '-'}
                    </span>
                  </FlexBox>
                  <FlexBox justifyContent="space-between">
                    <span className="qqt-title-label">Timing</span>
                    <span
                      className={classNames(
                        'qqt-status-label',
                        (timing && level[timing]) || ''
                      )}
                    >
                      {_.capitalize(timing && level[timing]) || '-'}
                    </span>
                  </FlexBox>
                </Space>
              </div>
            </Col>
          )
        })}
      </Row>
    </ScoreBreakdownContainer>
    </ConditionalHideWrap>
  )
}
