import React from 'react'
import { DatePicker, Button, Checkbox } from 'antd'
import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import moment from 'moment'
const FilterButton = ({
    isActive,
    onClick,
    label,
    activeClass,
    defaultClass
}) => (
    <Button
        className={classNames(isActive ? activeClass : defaultClass)}
        onClick={onClick}
    >
        {isActive && <CheckCircleFilled />}
        {label}
    </Button>
)

const FilterPanel = ({
    activeFilter,
    handleShowAll,
    handleFilterChange,
    handleDateRangeChange,
    loginRole,
    handleAddTransaction,
    isFlagChecked,
    handleCheckboxChange,
    defaultStartDate,
    defaultEndDate
}) => {
    const isFilterActive = (filter) => activeFilter === filter

    const handleDateChange = (dates) => {
        if (!dates || dates.length === 0) {
            handleShowAll()
        } else {
            const [startDate, endDate] = dates
            handleDateRangeChange({
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD')
            })
        }
    }

    return (
        <div className="filter-panel">
            <span>Apply Filters</span>
            <DatePicker.RangePicker
                style={{ width: '300px' }}
                placeholder={['Start Date', 'End Date']}
                value={[
                    defaultStartDate ? moment(defaultStartDate) : null,
                    defaultEndDate ? moment(defaultEndDate) : null
                ]}
                onChange={handleDateChange}
                onClear={handleShowAll}
            />
            <FilterButton
                isActive={isFilterActive('show_all')}
                onClick={handleShowAll}
                label="SHOW ALL"
                activeClass="show-all-filter"
                defaultClass="show-all-filter"
            />
            <FilterButton
                isActive={isFilterActive('credit')}
                onClick={() => handleFilterChange('credit')}
                label="CREDIT"
                activeClass="credit-value"
                defaultClass="credit-filter"
            />
            <FilterButton
                isActive={isFilterActive('debit')}
                onClick={() => handleFilterChange('debit')}
                label="DEBIT"
                activeClass="debit-value"
                defaultClass="debit-filter"
            />
            {(loginRole === 'InternalAdmin' || loginRole === 'Expert') && (
                <div className='admin-buttons'>
                    <Button type="primary" onClick={handleAddTransaction}>
                        <PlusOutlined />
                        ADD TRANSACTION
                    </Button>
                    <Checkbox checked={isFlagChecked} onChange={handleCheckboxChange}>
                        Admin Review
                    </Checkbox>
                </div>
            )}
        </div>
    )
}

export default FilterPanel
