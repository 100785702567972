import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Input, message } from 'antd'
import saveIcon from '../../../asserts/icon/saveIcon.svg'
import CancelIcon from '../../../asserts/icon/CancelIcon.svg'
import editIcon from '../../../asserts/icon/icon-profile-edit.svg'
import Reset from '../../../asserts/icon/Reset.svg'
import { defaultSessionPricingData } from '../../../utils/constant'

function SessionPricing({ sessionPricingData, onUpdate, onReset, userRole }) {
  const [editingKey, setEditingKey] = useState('')
  const [editingCache, setEditingCache] = useState({})

  const handleEdit = (key) => {
    setEditingKey(key)
    setEditingCache({})
  }

  const handleSave = (key) => {
    const index = sessionPricingData.findIndex((item) => item.key === key)

    if (index > -1) {
      const item = sessionPricingData[index]
      const updatedItem = { ...item, ...editingCache }

      if (String(updatedItem.clientPay).length === 0 || parseFloat(updatedItem.clientPay) < 0) {
        message.error('Client Pay cannot be empty or negative.')
        return
      }

      if (String(updatedItem.sessionsUsed).length === 0 || parseFloat(updatedItem.sessionsUsed) < 0) {
        message.error('Service Sessions cannot be empty or negative.')
        return
      }

      onUpdate(updatedItem, key)
      setEditingKey('')
    }
  }

  const handleCancel = () => {
    setEditingKey('')
    setEditingCache({})
  }

  const handleInputChange = (e, field) => {
    const value = e.target.value

    const regex = /^\d*(\.\d{0,2})?$/
    if (!regex.test(value)) {
      message.error('Only numbers with up to 2 decimal places are allowed')
      return
    }
    if (value !== '' && parseFloat(value) < 0) {
      message.error('Negative values are not allowed')
      return
    }
    setEditingCache((prev) => ({
      ...prev,
      [field]: value
    }))
  }

  const preventNegative = (e) => {
    const char = String.fromCharCode(e.which)
    if (!/[0-9]/.test(char) && e.which !== 8 && e.which !== 46) {
      e.preventDefault()
    }
  }

  const sessionPricingColumns = [
    {
      title: 'LOCATIONS',
      dataIndex: 'locations',
      key: 'locations',
      align: 'center'
    },
    {
      title: 'SESSIONS',
      dataIndex: 'sessionsUsed',
      key: 'sessionsUsed',
      align: 'center',
      render: (text, record) =>
        editingKey === record.key ? (
          <Input
            type="number"
            defaultValue={text}
            onKeyPress={(e) => preventNegative(e)}
            onChange={(e) => handleInputChange(e, 'sessionsUsed')}
            className='editable-input'
          />
        ) : (
          `${text}`
        )
    },
    {
      title: 'CLIENT PAY (PER HOUR)',
      dataIndex: 'clientPay',
      key: 'clientPay',
      align: 'center',
      render: (text, record) =>
        editingKey === record.key ? (
          <Input
            type="number"
            defaultValue={text}
            onKeyPress={(e) => preventNegative(e)}
            onChange={(e) => handleInputChange(e, 'clientPay')}
            className='editable-input'
          />
        ) : (
          `${text}`
        )
    },
    {
      title: 'ACTION',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        editingKey === record.key ? (
          <div>
            <Button type="link" onClick={() => handleSave(record.key)}>
              <img src={saveIcon} alt="save" style={{ height: '24px' }} />
            </Button>
            <Button
              type="link"
              onClick={handleCancel}
              style={{ marginLeft: '-20px' }}
            >
              <img src={CancelIcon} alt="cancel" style={{ height: '24px' }} />
            </Button>
          </div>
        ) : (
          <>
            <Button type="link" onClick={() => handleEdit(record.key)}>
              <img src={editIcon} alt="edit" />
            </Button>
            <Button
              type="link"
              onClick={() => onReset(record.key)}
              style={{ marginLeft: '-10px' }}
            >
              <img src={Reset} alt="reset" style={{ height: '16px' }} />
            </Button>
          </>
        )
    }
  ]

  return (
    <div>
      <div className="title session-pricing-header">
        Session Pricing
        <div className="line" />
      </div>
      <Table
        columns={sessionPricingColumns}
        dataSource={sessionPricingData}
        pagination={false}
        style={{ width: '800px' }}
      />
    </div>
  )
}

SessionPricing.propTypes = {
  sessionPricingData: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired
}

export default SessionPricing
