import React, { useReducer } from 'react'
import styled from 'styled-components'
import DatePickerByRange from './datePickByRange'
// import { Divider, Switch, Button, Select } from 'antd'
import moment from 'moment'
import _ from 'lodash'

const Container = styled.div`
  .tool-bar {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    .date-tips {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 12px;
      margin-right: 16px;

      li {
        display: inline-block;
        padding: 10px 8px;
        border: 1px solid #e4e7eb;
        color: #7b8794;
        cursor: pointer;
        &:not(:last-child) {
          border-right: none;
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        &.active {
          color: #3e4c59;
          background: #cbd2d9;
        }
        &:first-child {
          border-radius: 6px 0px 0px 6px;
        }
        &:last-child {
          border-radius: 0px 6px 6px 0px;
        }
      }
    }
    .ant-calendar-picker {
      width: 227px;
      input {
        color: #616e7c;
      }
    }
  }
`

const _dateRanges = [
  {
    name: 'Week',
    barWidth: 20,
    picker: 'week',
    formatX: 'ddd',
    formatTooltip: 'ddd D'
  },
  {
    name: 'Bi-Weekly',
    barWidth: 12,
    formatX: 'MM/DD',
    picker: 'week',
    type: 'bi_weekly'
  },
  {
    name: 'Month',
    barWidth: 8,
    picker: 'month',
    formatX: 'MMM/D'
  },
  {
    name: 'Quarterly',
    barWidth: 12,
    picker: 'quarter',
    type: 'quarterly',
    formatX: 'MM/DD'
  },
  {
    name: 'Annual',
    barWidth: 20,
    type: 'annual',
    picker: 'year',
    formatX: 'MMM'
  },
  {
    name: 'Lifetime',
    barWidth: 8,
    picker: 'date',
    type: 'lifetime'
    // formatX: 'M/D/YY'
  },
  {
    name: 'Custom',
    picker: 'date',
    type: 'custom'
    // formatX: ''
  }
]

export default function DateGroupPicker(props) {
  const { fetchData, defaultSelectedDate, display = true } = props

  const dateRanges = props.dateRanges || _dateRanges

  const [state, dispatch] = useReducer(
    (state, action) => {
      if (action.payload) {
        return _.assign({}, state, action.payload)
      }
      return state
    },
    {
      selectedDate: defaultSelectedDate || 0,
      dateRange: dateRanges[defaultSelectedDate || 0],
      from: props.from,
      to: props.to || moment().format('YYYY-MM-DD')
    }
  )

  const { selectedDate, dateRange, from, to } = state

  return (
    <Container
      style={{
        display: display ? '' : 'none'
      }}
    >
      <div className="tool-bar">
        <ul className="date-tips">
          {dateRanges.map((item, index) => (
            <li
              key={index}
              className={`${selectedDate === index ? 'active' : ''} ${
                item.disabled ? 'disabled' : ''
              }`}
              onClick={() => !item.disabled && switchDate(index)}
            >
              {item.name}
            </li>
          ))}
        </ul>
        {dateRange.name !== 'Lifetime' && (
          <DatePickerByRange {...{ dateRange, onRangeChange, from, to }} /> //from, to, , onRangeChange
        )}
      </div>
    </Container>
  )

  async function switchDate(index) {
    const dateRange = dateRanges[index]
    let { from, to } = state
    switch (dateRange.name) {
      case 'Week':
        from = moment()
          .startOf('week')
          .format('YYYY-MM-DD')
        to = moment()
          .endOf('week')
          .format('YYYY-MM-DD')
        break
      case 'Month':
        from = moment()
          .startOf('month')
          .format('YYYY-MM-DD')
        to = moment()
          .endOf('month')
          .format('YYYY-MM-DD')
        break
      case 'Quarterly':
        from = moment()
          .startOf('quarter')
          .format('YYYY-MM-DD')
        to = moment()
          .endOf('quarter')
          .format('YYYY-MM-DD')
        break
      case 'Year':
      case 'Annual':
        from = moment()
          .startOf('year')
          .format('YYYY-MM-DD')
        to = moment()
          .endOf('year')
          .format('YYYY-MM-DD')
        break
      case 'Lifetime':
        from = null
        to = null
        break

      case 'Custom':
        const _from = from || moment().format('YYYY-MM-DD')
        const _to = to || moment().format('YYYY-MM-DD')
        dispatch({
          type: 'update',
          payload: {
            selectedDate: index,
            dateRange: dateRanges[index],
            from: _from,
            to: _to
          }
        })
        return
      default:
        break
    }

    fetchData && (await fetchData(from, to, dateRange.type || dateRange.picker))
    dispatch({
      type: 'update',
      payload: { selectedDate: index, dateRange: dateRanges[index], from, to }
    })
  }

  async function onRangeChange([from, to]) {
    const dateRange = dateRanges[selectedDate]

    await fetchData(
      from,
      to || moment().format('YYYY-MM-DD'),
      dateRange.type || dateRange.picker
    )

    dispatch({
      type: 'update',
      payload: { from, to: to || moment().format('YYYY-MM-DD') }
    })
  }

}
