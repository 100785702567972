import React from 'react'
import { Col, Modal, Row } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import AppStore from '../../../asserts/images/app-store.svg'
import AppStoreQrCode from '../../../asserts/images/app-store-qr-code.png'
import checkIcon from '../../../asserts/icon/client/check.png'
import warningIcon from '../../../asserts/icon/client/warning.png'
import { autoSource } from '../../../models/goals.form'
import { firstUpperCase } from '../../../utils/common'
import phsLogo from '../../../asserts/icon/latest-logo.svg'

const { info } = Modal

const Container = styled.section`
  .tags {
    display: flex;
    justify-content: flex-end;
    margin-top: -47px;
    span {
      margin-left: 16px;
      display: block;
      padding: 2px 8px;
      background: rgba(217, 220, 227, 0.2);
      border: 1px solid rgba(33, 57, 89, 0.2);
      border-radius: 19px;
      color: #5a657c;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 12px;
      svg {
        margin-right: 6px;
        vertical-align: middle;
        margin-top: -2px;
      }
    }
    &.User {
      margin-top: -67px;
    }
  }
  .device-and-appstore-link-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;
    .device-status-container {
      color: rgba(76, 96, 114, 1);
      .devices-container {
        display: flex;
        align-items: center;
        margin-top: 17px;
        .item {
          margin-right: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .item-images {
            position: relative;
            cursor: pointer;
            .status {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 12px;
            }
          }
          .sync-info-title {
            font-size: 9px;
            color: rgba(96, 101, 109, 1);
            margin-top: 16px;
          }
          .sync-info {
            margin-top: 4px;
            color: #60656d;
            &.error {
              color: #e75f25;
            }
          }
        }
      }
    }
    & > img {
      padding-top: 20px;
      cursor: pointer;
      height: 60px;
    }
  }
`

export default function PersonalInfoSection(props) {
  const {
    person,
    latest_height,
    latest_weight,
    residence,
    id,
    device_info
  } = props
  return (
    <Container id={id}>
      <h1 className="section-title">Personal Information</h1>
      <Row
        gutter={80}
        style={{
          marginTop: 45
        }}
      >
        <Col span={13}>
          <Detail
            person={person}
            {...{ latest_height, latest_weight, residence }}
          />
        </Col>
        <Col span={11}>
          <div className="device-and-appstore-link-container">
            <div className="device-status-container">
              Connected Wearables
              <div className="devices-container">
                {autoSource.map((item, index) => {
                  const device = _.get(device_info, item.value, {})
                  return (
                    <div key={index} className="item">
                      <div className="item-images">
                        <img
                          src={item.leftExtra}
                          alt=""
                          style={{ width: 48 }}
                        />
                        {device.connection_status === 'Connected' && (
                          <img className="status" src={checkIcon} alt="check" />
                        )}
                        {device.connection_status === 'Disconnect' && (
                          <img
                            className="status"
                            src={warningIcon}
                            alt="warning"
                          />
                        )}
                      </div>
                      <div className="sync-info-title">LAST SYNCED:</div>
                      <span
                        className={`sync-info ${
                          device.connection_status === 'Disconnect'
                            ? 'error'
                            : ''
                        }`}
                      >
                        {device.last_sync
                          ? moment(
                              device.last_sync.toString().substr(0, 15),
                              'ddd MMM D YYYY'
                            ).format('MM/DD/YY')
                          : '--'}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>

            <img
              src={AppStore}
              alt=""
              onClick={() => {
                info({
                  bodyStyle: {
                    padding: '24px 24px 0px 24px'
                  },
                  content: (
                    <QrCodeContainer>
                      <img src={phsLogo} alt="logo" />
                      <p>
                        Use your phone to scan the QR code to download the
                        Apeiron app.
                      </p>
                      <img
                        src={AppStoreQrCode}
                        alt="qrCode"
                        style={{ width: 300 }}
                      />
                    </QrCodeContainer>
                  ),
                  width: '410px',
                  cancelButtonProps: { style: { display: 'none' } },
                  okButtonProps: { style: { display: 'none' } },
                  icon: <></>,
                  closable: true
                })
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

const DetailContainer = styled.div`
  background-color: rgba(250, 250, 250, 1);
  .user {
    height: fit-content;
    padding: 32px 24px;
    border: 1px solid #e3e6ea;
    border-radius: 4px;
    .profile-item {
      width: 50%;
      display: inline-block;
      letter-spacing: 0.3px;
      font-size: 15px;
      margin-bottom: 30px;
      color: rgba(76, 96, 114, 1);
      .label {
        margin-bottom: 0px;
      }
      .value {
        font-size: 24px;
      }
    }
    .profile-item:last-child {
      width: 100%;
    }
  }
`

const QrCodeContainer = styled.div`
  text-align: center;
  p {
    padding-top: 32px;
    margin: 0px;
    font-family: 'Gilroy';
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.3px;
    color: #4c6072;
  }
`
function parseResidence(residence) {
  if (!residence || !residence.includes(',')) {
    return '--'
  }

  const residenceParts = residence.split(',').map((part) => part.trim())

  const city = residenceParts.length > 1 ? residenceParts[1] : ''
  const state = residenceParts.length > 2 ? residenceParts[2] : ''

  return city && state
    ? `${city}, ${state}`
    : city
      ? `${city}, `
      : state
        ? `, ${state}`
        : '--'
}

function Detail(props) {
  const { person, latest_height, latest_weight, residence } = props
  const age = moment().diff(person.profile.date_of_birth, 'years')
  const height = latest_height || person.profile.height_inch
  const weight = latest_weight || person.profile.weight_lbs
  const formattedResidence = parseResidence(residence)

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: false
  })

  const profileItems = [
    {
      label: 'Gender',
      value: firstUpperCase(person.profile.gender)
    },
    {
      label: 'Age',
      value: age || '--'
    },
    {
      label: 'Height',
      value: height ? `${height} inches` : '--'
    },
    {
      label: 'Weight',
      value: weight ? `${formatter.format(weight)} lbs` : '--'
    },
    {
      label: 'Residence',
      value: formattedResidence
    }
  ]

  return (
    <DetailContainer>
      <div className="user">
        {profileItems.map((item, index) => (
          <div className="profile-item" key={index}>
            <div className="label">{item.label}</div>
            <div className="value">{item.value}</div>
          </div>
        ))}
      </div>
    </DetailContainer>
  )
}
