import React from 'react'
import HideWrap from '../comp/hideWrap'

const ConditionalHideWrap = ({
  name,
  title,
  syncStatus,
  status,
  view,
  hideSection,
  condition,
  children,
  isSection,
  isMovementQualityMetrics,
  isMuscleMetrics,
  isIndividuals,
  isIntroduction,
  isLifestyleScore
}) => {
  if (!condition) {
    return null
  }

  return (
    <HideWrap
      name={name}
      title={title}
      syncStatus={syncStatus}
      status={status}
      view={view}
      isSection={isSection}
      isMovementQualityMetrics={isMovementQualityMetrics}
      isMuscleMetrics={isMuscleMetrics}
      isIndividuals={isIndividuals}
      isIntroduction={isIntroduction}
      isLifestyleScore={isLifestyleScore}
    >
      {children}
    </HideWrap>
  )
}

export default ConditionalHideWrap
